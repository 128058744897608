import React from "react";
import styled from "styled-components";
import qrcode from "../../images/qrcode.png";
import mapImg from "../../images/contact_map.png";
import callphone from "../../images/callphone.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const ContactUs = () => {
  const { i18n } = useTranslation();
  return (
    <Container>
      <ContactMain>
        {i18n.language === "zh" ? <QrCode src={qrcode} /> : <QrCode2 src={qrcode} />}
        {i18n.language === "zh" && (
          <>
            <QrP1>晤桥亚洲资产管理系统与咨询</QrP1>
            <QrP2>
              国内唯一专注于资产管理大数据
              <br />
              数字化BI平台搭建与咨询的专业团队
            </QrP2>
          </>
        )}
        <Sgp>{i18n.language === "en" ? "Shenzhen Office" : "深圳办公室"}</Sgp>
        <SgpMapImg src={mapImg} />
        <SgpPlace>深圳市南山区航天科技广场b座7层b11</SgpPlace>
        {i18n.language === "en" && <SgpPlace style={{ top: "145px" }}>Shenzhen Nanshan District Center Haide 3rd Road, 7F-B11 </SgpPlace>}
        <Sz>{i18n.language === "en" ? "Beijing Office" : "北京办公室"}</Sz>
        <SzMapImg src={mapImg} />
        <SzPlace>北京市昌平区天通科技园G2楼607</SzPlace>
        {i18n.language === "en" && <SzPlace style={{ top: "260px" }}>Beijing Changping District Tiantong Science Park, G2-607</SzPlace>}
        <PhoneImg src={callphone} />
        <ContactEason>15280263033 {i18n.language === "en" ? "Contact" : "联系人"}：Xiyan</ContactEason>
      </ContactMain>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const ContactMain = styled.div`
  width: 1130px;
  height: 485px;
  position: relative;
  p {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }
`;
const QrCode = styled.img`
  width: 154px;
  height: 151px;
  object-fit: contain;
  position: absolute;
  top: 74px;
  left: 151px;
`;
const QrCode2 = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
  position: absolute;
  top: 110px;
  left: 151px;
`;
const QrP1 = styled.p`
  width: 294px;
  height: 47px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  text-align: center;
  color: #2f3852;
  position: absolute;
  top: 241px;
  left: 80px;
`;
const QrP2 = styled.p`
  width: 376px;
  height: 61px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.6px;
  text-align: center;
  color: #2f3852;
  position: absolute;
  top: 285px;
  left: 40px;
`;
const Sgp = styled.p`
  width: 451px;
  height: 29px;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: normal;
  color: #829aba;
  position: absolute;
  top: 83px;
  left: 559px;
`;
const SgpMapImg = styled.img`
  width: 16px;
  height: 22px;
  position: absolute;
  top: 117px;
  left: 559px;
`;
const SgpPlace = styled.p`
  width: 451px;
  height: 26px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  color: #2f3852;
  position: absolute;
  top: 117px;
  left: 586px;
`;
const Sz = styled.p`
  width: 451px;
  height: 29px;
  font-size: 21px;
  font-weight: normal;
  letter-spacing: normal;
  color: #829aba;
  position: absolute;
  top: 196px;
  left: 559px;
`;
const SzMapImg = styled.img`
  width: 16px;
  height: 22px;
  position: absolute;
  top: 230px;
  left: 559px;
`;
const SzPlace = styled.p`
  width: 451px;
  height: 26px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  color: #2f3852;
  position: absolute;
  top: 230px;
  left: 586px;
`;
const PhoneImg = styled.img`
  width: 20px;
  height: 19px;
  object-fit: contain;
  position: absolute;
  top: 315px;
  left: 559px;
`;
const ContactEason = styled.p`
  width: 625px;
  height: 24px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  color: #2f3852;
  position: absolute;
  top: 312px;
  left: 585px;
`;
