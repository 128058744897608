import React from "react";
import Layout from "../components/layout";
import { NavBar } from "../components/nav-bar";
import { Footer } from "../components/Footer/index";
import { ContactUs } from "../components/Contact";
import { graphql } from "gatsby";
import BG from "../images/contact/bg.webp";
import styled from "styled-components";

export default () => {
  return (
    <Layout>
      <Container bg={BG}>
        <NavBar main />
        <ContactUs />
      </Container>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top -50%;
`;
